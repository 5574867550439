@font-face {
    font-family: Montserrat-regular;
    src: url("../assets/fonts/Montserrat-Regular.ttf");
}

@font-face {
    font-family: Montserrat-semibold;
    src: url("../assets/fonts/Montserrat-SemiBold.ttf");
}

@font-face {
    font-family: Acumin_Variable;
    src: url("../assets/fonts/AcuminVariableConcept.otf");
}



html,
body {
    scroll-behavior: smooth;
    font-family: Acumin_Variable !important;
    overflow-x: hidden;
}

.img_last_banner {
    width: 100%;
}

.img_last_banner img {
    width: 100%;
    object-fit: cover;
}

.btn_achore {
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 1px;
    text-decoration: none;
    color: black;
}

.logo_class {
    width: auto;
    height: 53px;
}

.logo_class img {
    width: auto;
    height: inherit;
    object-fit: contain;
}

.phon_img img {
    width: 100%;
}

.phon_img {
    width: 40px;
    height: 40px;
}

.phone_class {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    gap: 25px;
}

.phone_class h6 {
    font-size: 25px;
    color: #003994;
    font-weight: 600;
}

.header_class .appBar {
    background-color: white;
    /* flex-direction: row; */
}

.logo_container .MuiBox-root {
    display: flex;
    justify-content: space-between;
    padding: 15px 50px 15px 50px;
    align-items: center;
}

.new_logo {
    width: auto;
    height: 53px;
}

.new_logo img {
    width: 100%;
    height: inherit;
    object-fit: contain;
}

.container_phon h6 a {
    text-decoration: none;
    color: inherit;
}

.container_phon {
    cursor: pointer;
    z-index: 99;
    position: fixed;
    padding: 10px 20px;
    border-radius: 50px;
    bottom: 20px;
    right: -130px;
    display: flex;
    gap: 10px;
    align-items: center;
    background-color: #F6C800;
    box-shadow: 0 0 50px gray;
    transition: all 0.8s ease-in-out;
}

.container_phon:hover {
    right: 10px;
}

.footer_class {
    align-items: center;
    justify-content: center;
    background-color: #DE4088;
    color: black;
    padding: 20px;
    display: flex;
    gap: 50px;
    flex-wrap: wrap;
}

.logo1 img,
.logo2 img,
.logo3 img {
    width: 100%;
    height: 80px;
    min-height: 80px;
    max-height: 80px;
    object-fit: contain;

}

.logo1 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30%;
    /* height: 80px; */
    background-color: white;
    padding: 5px;
}

.logo2 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30%;
    background-color: white;
    padding: 5px;
}

.logo3 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30%;
    background-color: white;
    padding: 5px;
}

.fist_cont {
    display: flex;
    gap: 15px;
    align-items: center;
    justify-content: center;
}

.sec_cont h4 {
    font-size: 25px;
    margin: 0;
}

.fist_cont,
.sec_cont {
    width: 47%;
}

.bann_img_cls {
    width: 100%;
    position: relative;
}

.bann_img_cls .banner_img {
    display: block;
    width: 100%;
    height: 750px;
    object-fit: cover;
    /* transform: scaleX(-1); */
    padding: 0 0 50px 0;
}

.bann_img_cls .text_ban_cls {
    text-align: end;
    position: absolute;
    top: 5%;
    right: 5%;
    color: white;
    font-size: 48px;
    font-weight: 400;
    line-height: 50px;

}

.form_div {
    width: 350px;
    background-color: white;
    border: 2px solid transparent;
    border-radius: 25px;
    position: absolute;
    right: 5%;
    top: 25%;
    padding: 20px 25px 40px 25px;
}

.form_ti {
    text-align: center;
    text-transform: uppercase;
    font-weight: 600 !important;
    font-size: 27px !important;
}

.form_text_con {
    display: flex;
    align-items: flex-end;
    margin: 25px 0;
    border-bottom: 1px solid;
}

.form_text_con h6 {
    border-bottom: 1px solid transparent;
    font-weight: 500;
    font-size: 12px;
    text-transform: uppercase;
}

.form_text_con .text_field_form input {
    color: #7455ac;
    padding: 0px 15px;
    font-size: 13px;
}

.form_text_con .text_field_form {
    min-width: 60% !important;
    border-bottom: 1px solid transparent;
}

.form_text_con .text_field_form div::before,
.form_text_con .text_field_form div::after {
    border: 0 !important;
}

.p_global_edu {
    text-align: center;
    font-size: 30px !important;
    width: 80%;
    margin: 30px auto !important;
    font-family: Acumin_Variable !important;
}

.a_star_text {
    position: absolute;
    top: 20%;
    left: 5%;
    font-size: 60px !important;
    font-family: Acumin_Variable !important;
    font-weight: 600 !important;
}

.form_btn {
    letter-spacing: 1.5px !important;
    border-radius: 10px !important;
    position: absolute !important;
    bottom: -20px;
    left: 0;
    right: 0;
    width: 50%;
    margin: 0 auto !important;
    /* background-color: #003994 !important; */
    background-image: linear-gradient(90deg, rgb(139, 90, 164), rgb(166, 59, 146), rgb(139, 90, 164));
    font-weight: 600 !important;
}

.social_media_container {
    background-color: black;
    border: 1px solid transparent;
    border-radius: 0 10px 10px 0;
    position: absolute;
    top: 30%;
    left: 0;
    padding: 10px;
}

.social_img_div {
    margin: 20px 0;
    width: 25px;
    height: 25px;
}

.social_img_div img {
    width: 100%;
    height: 25px;
    object-fit: contain;
}

.tit_continer {
    text-align: center;
    margin: 0 auto;
}

.title_of_section {
    font-family: Acumin_Variable !important;
    text-align: center;
    font-size: 35px !important;
    font-weight: 600 !important;
    letter-spacing: 2px !important;
    color: white;
    background-image: linear-gradient(to right, #8b5aa4, #a63b92, #8b5aa4);
    width: fit-content !important;
    padding: 12px 50px;
    /* border: 1px solid transparent; */
    border-radius: 60px;
    margin: 40px auto !important;
}

.banner_about_container {
    width: 100%;
    height: 500px;
}

.banner_about_container img {
    width: 100%;
    height: inherit;
    object-position: 0px -50px;
    object-fit: cover;
}


.img_abt_cont {
    /* overflow: hidden; */
    /* display: flex; */
    /* justify-content: space-between; */
    padding: 10px 0 30px 0;
    margin: 50px 0 50px 0;
}

.img_about {
    width: 90% !important;
    position: relative;
    margin: 40px 0;

}

.close_div {
    cursor: pointer;
    text-align: end;
    position: absolute;
    top: 5px;
    right: 5px;
}

.img_about img {
    width: 100%;
    height: 400px;
    object-fit: cover;
}

.text_cont_abot {
    width: 60%;
    font-size: 35px !important;
    text-align: start;
}

.text_cont_abot ul,
.text_cont_abot ul li {
    margin: 10px 0;
    width: fit-content;
    padding: 0;
    font-size: 17px;
}

.clas_div_abt {
    opacity: 0;
    width: 100%;
    position: absolute;
    top: -500px;
    background-color: #ffffffd1;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 1s ease-in-out;
}

.img_about:hover .clas_div_abt {
    opacity: 1;
    top: 0;
}

.read_more_main {
    text-align: end;
    display: flex;
    align-items: center;
    justify-content: center;
}

.about_img_tit {
    /* font-size: 10px; */
    min-height: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 65%;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: -40px;
    padding: 15px 20px;
    background-color: #7455ac;
    color: white;
}

.img_abt_cont .slick-slide {
    /* padding: 0 15px; */
}

.btn_read_more {
    background-color: transparent !important;
    color: black !important;
    font-size: 22px !important;
    box-shadow: none !important;
    padding: 0 10px !important;
}

.about_img_tit h4 {
    font-size: 30px;
    font-weight: 600;
}

.finish_edge_main {
    position: relative;
    max-width: 1300px;
    margin: 0 auto 50px auto;
    padding-bottom: 50px;
}

.image_edge_container {
    padding: 30px 0 50px 0;
    /* display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 45px; */
}

.image_edge_container .slick-track {
    display: flex;
}

.image_edge_container .slick-initialized .slick-slide {
    /* padding: 0 20px; */
}

.img_finish {
    display: block !important;
    width: 90% !important;
    margin: 0 auto;
    position: relative;
}

.img_finish img {
    width: 100%;
    display: block;
    height: 390px;
    object-fit: cover;
}

.close_div p {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
}

.li_model {
    font-size: 18px;
}

.model_aboutus h2 {
    font-size: 25px;
    background-color: #8b5aa4;
    color: white;
    padding: 10px;
    text-align: center;
    font-weight: 600;
}

.new_icon_text_section {
    display: flex;
    margin: 12px 0;
    color: white;
}

.mennu_mobile {
    display: none;
}

.sec_icon_right {
    position: absolute;
    right: 100px;
    top: -30px;
}

.sec_icon_right img {
    width: 100%;
}

.sec_icon_left {
    position: absolute;
    left: 100px;
    top: -30px;
}

.sec_icon_left img {
    width: 100%;
}

.img_finish:hover .finish_details_data {
    top: 0px;
}

.menu_nav_a {
    text-decoration: none;
    color: inherit;
    font-size: 20px;
    font-weight: 400;
}

.error_msg {
    text-align: center;
    color: red;
    font-size: 17px !important;
}

.title_data {
    background-image: linear-gradient(90deg, rgb(139, 90, 164), rgb(166, 59, 146), rgb(139, 90, 164));
    color: white;
    padding: 20px 30px;
    width: fit-content;
    max-width: 90%;
    margin: 0 auto;
    text-align: center;
}

.curriculum_text h5 {
    padding: 50px 0 80px 0;
    text-align: center;
    width: 80%;
    margin: 0 auto;
    font-size: 27px;
    font-family: Acumin_Variable;
    font-weight: 400;
    letter-spacing: 0.5px;
}

.title_data h2 {
    font-family: Acumin_Variable;
    font-size: 40px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
}

.school_stages_container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 70px 0 30px 0;
}

.sch_images_cont {
    width: 50%;
    height: 500px;
    position: relative;
}

.school_img {
    height: inherit;
}

.imags_text {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 10%;
    width: 80%;
    display: flex;
    height: 80%;
    background-color: rgba(0, 0, 0, 0.301);
    align-items: center;
    justify-content: center;
}

.imags_text h3 {
    width: 100%;
    text-align: center;
    color: white;
}

.school_img img {
    width: 100%;
    height: inherit;
    object-fit: cover;
    object-position: bottom;
}

.star_born_cont img {
    width: 100%;
    transform: scaleX(-1);
    object-fit: cover;
    object-position: bottom;
    height: 900px;
}

.star_born_cont {
    position: relative;
}

.btn_learn_more_div {
    position: absolute !important;
    bottom: 30px;
    left: 0;
    right: 0;
    width: fit-content;
    margin: 0 auto !important;
    padding: 20px 0;
}

.butn_lern_more {
    display: inline-block;
    position: relative;
    /* width: fit-content;
    margin: 0 auto !important; */
    text-transform: capitalize !important;
    background-image: linear-gradient(90deg, rgb(139, 90, 164), rgb(166, 59, 146), rgb(139, 90, 164));
    border: none !important;
    letter-spacing: 1.5px !important;
    font-size: 20px !important;
    border-radius: 50px !important;
    padding: 13px 35px 10px 35px !important;
    text-decoration: none;
    color: white;
    overflow: hidden;
    z-index: 1;
}

.butn_lern_more::before {
    transition: all 1s ease-in-out;
    position: absolute;
    content: "";
    top: 50%;
    left: 50%;
    width: 100%;
    height: 0;
    background-image: linear-gradient(90deg, rgb(20, 23, 233), rgb(34, 190, 73), rgb(36, 16, 214));
    z-index: -1;
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
}

.butn_lern_more:hover:before {
    border: 1px solid white;
    height: 450%;
}

.btn_contact_container {
    text-align: center;
    padding: 50px 0;
}

.butn_contact_us {
    overflow: hidden;
    z-index: 1;
    display: inline-block !important;
    position: relative !important;
    /* bottom: 30px;
    left: 0;
    right: 0; */
    width: fit-content;
    margin: 0 auto !important;
    text-transform: capitalize !important;
    background-image: linear-gradient(90deg, rgb(139, 90, 164), rgb(166, 59, 146), rgb(139, 90, 164));
    border: none !important;
    letter-spacing: 1.5px !important;
    font-size: 20px !important;
    border-radius: 50px !important;
    padding: 10px 35px !important;
    text-decoration: none;
    color: white;
}

.butn_contact_us::before {
    transition: all 1s ease-in-out;
    position: absolute;
    content: "";
    top: 50%;
    left: 50%;
    width: 100%;
    height: 0;
    background-image: linear-gradient(90deg, rgb(20, 23, 233), rgb(34, 190, 73), rgb(36, 16, 214));
    z-index: -1;
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
}

.butn_contact_us:hover:before {
    border: 1px solid white;
    height: 450%;
}

.Goneka_glb_educ_main {
    padding: 50px 0;
}

.educluster_fin_main {
    background-color: #e8d4ef;
    margin: 0 0 50px 0;
}

.educluster_text_container {
    width: 80%;
    margin: 0 auto;
    padding: 50px 0;
}

.educluster_text_container h2:first-child {
    font-weight: 600;
}

.educluster_text_container h2 {
    font-size: 27px;
    text-align: center;
    padding: 15px 0;
    font-family: Acumin_Variable !important;
}

.OurGlobleFaculty_img_text {
    width: 90%;
    margin: 30px auto;
    display: flex;
    flex-wrap: wrap;
}
.add_height{overflow: auto !important;}
.read_sch{
    display: flex;
    align-items: center;
}
.pgraph_cont{
    height: 100px;
    overflow: hidden;
}
.read_more_glb_faculty {
    cursor: pointer;
    padding-top: 20px;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.read_more_glb_faculty h4 {
    font-size: 23px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
}

.globle_image_cont {
    width: 35%;
}

.read_con_glb {
    height: 200px;
    overflow: hidden;
    transition: all 1s ease-in-out;
}

.globle_paragraph_cont {
    width: 65%;
}

.OurGlobleFaculty_img_text .name_image_contest {
    font-size: 24px;
    font-family: Acumin_Variable;
    font-weight: 600;
    margin-top: 15px;
}

.OurGlobleFaculty_img_text .main_name_tit {
    font-size: 27px;
    font-weight: 600;
    font-family: Acumin_Variable
}

.globle_paragraph_cont h2 {
    font-size: 23px;
    padding: 20px 0 0 0;
    font-family: Acumin_Variable
}

/* .slider_activies_1{display: flex;} */
.images_activi_cont {
    width: 100%;
    height: 800px;
    position: relative;
}

.images_activi_cont img {
    object-fit: cover;
    height: inherit;
    object-position: center;
    width: 100%;
}


.activies_title_bg {
    display: flex;
    height: 100%;
    position: absolute;
    top: -800px;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.616);
    transition: all 1.5s ease-in-out;
}

.activies_title_bg h3 {
    text-align: center;
    color: white;
}

.images_activi_cont:hover .activies_title_bg {
    top: 0;
}

.Activites_slider_main {
    padding: 50px 0;
}

.extra_activities_main {
    padding: 30px 0 50px 0;
    display: flex;
    flex-wrap: wrap;
    position: relative;
}

.Finnish_edge_main {
    background-color: #e6d1f0;
    margin-bottom: 50px;
}

.finland_slider_main {
    margin-bottom: 50px;
}

.finnish_five_div_cont {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    /* align-items: center; */
    padding: 50px 0;
    /* gap: 20px; */
}

.call_us_now_btn {
    padding: 0 0 50px 0;
}

.finnish_all_content {
    padding: 15px 20px;
    text-align: center;
    width: 28%;
    display: flex;
    align-items: center;
    /* justify-content: center; */
    flex-direction: column;
    /* gap: 20px; */
}

.fin_conte_icon {
    height: 90px;
}

.fin_conte_icon img {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.finnish_all_content h2 {
    color: rgb(103, 103, 103);
    width: 60%;
    font-size: 28px;
    font-family: Acumin_Variable !important;
    font-weight: 600 !important;
    margin: 20px auto;
}

.finnish_all_content h6 {
    color: rgb(103, 103, 103);
    width: 85%;
    font-size: 22px;
    font-family: Acumin_Variable !important;
    margin: 0 auto;
}

.finnish_all_content:nth-child(2),
.finnish_all_content:nth-child(4) {
    position: relative;
}

.finnish_all_content:nth-child(2),
.finnish_all_content:nth-child(1),
.finnish_all_content:nth-child(3) {
    border-bottom: 1px solid gray;
}

.finnish_all_content:nth-child(2)::after {
    content: "";
    background-color: gray;
    position: absolute;
    width: 1.5px;
    height: 70%;
    top: 20%;
    left: 0;
}

.finnish_all_content:nth-child(2)::before,
.finnish_all_content:nth-child(4)::before {
    content: "";
    background-color: gray;
    position: absolute;
    width: 1.5px;
    height: 70%;
    top: 20%;
    right: 0;
}

.finnish_all_content:nth-child(4),
.finnish_all_content:nth-child(5) {
    margin: 40px 0 0 0;
}

.image_activities_container {
    width: 50%;
    height: 500px;
    position: relative;
}

.image_activities_container img {
    height: inherit;
    object-fit: cover;
    width: 100%;
}

.extra_text_container {
    position: absolute;
    /* bottom: 20px;
    right: 20px; */
    width: 70%;
    display: flex;
    flex-direction: column;
}

.extra_posi1 {
    bottom: 20px;
    right: 20px;
}

.extra_posi2 {
    bottom: 20px;
    left: 20px;
}

.extra_posi3 {
    top: 20px;
    right: 20px;
}

.extra_posi4 {
    top: 20px;
    left: 20px;
}

.extra_tit {
    background-color: #8e32c1b8;
    padding: 25px 40px;
    min-height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.extra_tit h3 {
    font-size: 23px !important;
    color: white;
    text-align: center;
    font-family: Acumin_Variable !important;
}

.point_extra_conte {
    background-color: rgba(0, 0, 0, 0.637);
    color: white;
}

.point_extra_conte h4 {
    font-size: 23px !important;
    padding: 0px 40px;
    font-family: Acumin_Variable !important;
    font-weight: 100;
}

.point_extra_conte h6 {
    font-size: 18px !important;
    padding: 10px 40px;
    font-family: Acumin_Variable !important;
    font-weight: 500;
    text-align: end;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.finland_banner_div {
    height: 940px;
}

.finland_banner_div img {
    width: 100%;
    object-fit: cover;
    height: inherit;
}

.cross_curri_div_cont {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    width: 95%;
    margin: 0 auto;
}

.cross_img_text_cont {
    width: 32%;
    height: 490px;
    position: relative;
    overflow: hidden;
}

.cross_img_text_cont img {
    width: 100%;
    height: inherit;
    object-fit: cover;
    object-position: center;
}

.cross_all_text_div {
    transition: all 1s ease-in-out;
    position: absolute;
    top: 55%;
    background-color: rgba(0, 0, 0, 0.596);
    color: white;
    padding: 10px 40px;
    height: 100%;
}

.cross_acti_read_more {
    top: 0 !important;
}

.cross_all_text_div h2 {
    font-size: 21px;
    font-family: Acumin_Variable !important;
    font-weight: 600;
    margin: 10px 0 20px 0;
    min-height: 50px;
    display: flex;
    align-items: center;
}

.Read_more_div {
    transition: all 1s ease-in-out;
    height: 80px;
    overflow: hidden;
}

.cross_acti_read_more .read_more_height {
    height: auto;
}

.cross_all_text_div h6 {
    padding-bottom: 10px;
    font-size: 21px;
    font-family: Acumin_Variable !important;
    font-weight: 100;
    line-height: 27px;
}

.point_extra_conte h6,
.cross_all_text_div h2,
.cross_all_text_div h6,
.cross_all_text_div h4 {
    cursor: pointer;
}

.point_extra_conte h6:hover,
.cross_all_text_div h2:hover,
.cross_all_text_div h6:hover,
.cross_all_text_div h4:hover {
    color: #F6C800;
}

.cross_all_text_div h4:hover .arrow_svg,
.point_extra_conte h6:hover .point_extra_conte h6 svg {
    transform: translateX(5px);
}

.arrow_svg {
    transition: all 500ms ease-in-out;

}

.cross_all_text_div h4 {
    transition: all 500ms ease-in-out;
    font-size: 18px !important;
    padding: 10px 0px;
    font-family: Acumin_Variable !important;
    font-weight: 500;
    text-align: end;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.main_p_cross {
    text-align: center;
    margin: 40px 0 !important;
    font-size: 27px !important;
    font-family: Acumin_Variable !important;
}
.pr_image_container{
    height: 300px;
    width: 100%;
    /* padding: 10px; */
}
.pr_image_container img{
    width: 100%;
    object-fit: cover;
    object-position: top center;
    height: 300px;
}
.read_less_clas {
    height: auto;
}

@media screen and (max-width:1300px) {
    .menu_nav_a{
        font-size: 15px;
    }
    .finland_banner_div{height: auto;}

    .cross_all_text_div {
        padding: 10px 20px;
        top: 50%;
    }

    .extra_text_container {
        width: 80%;
    }

    .extra_tit {
        padding: 25px 10px;
    }

    .extra_tit h3 {
        font-size: 20px !important;
    }

    .bann_img_cls .text_ban_cls {
        font-size: 45px;
        line-height: 46px;
    }

    .sec_icon_right,
    .sec_icon_left {
        width: 150px;
        top: -1%;
    }

    .sec_icon_left {
        left: 5%;
    }

    .sec_icon_right {
        right: 5%;
    }

    .tit_continer {
        width: 90%;
        margin: 0 auto;
    }

}


@media screen and (max-width:1050px) {
    .cross_img_text_cont {
        width: 70%;
        margin: 0 auto;
    }

    .bann_img_cls .banner_img {
        height: 600px;
    }

    .menu_nav_a {
        font-size: 15px;
    }

    .bann_img_cls .text_ban_cls {
        left: 0;
        right: 0;
        width: fit-content;
        margin: 0 auto;
    }

    .social_media_container {
        top: 170px;
    }

    .form_div {
        position: relative;
        margin: -20px auto 70px auto;
        right: 0;
        left: 0;
        top: -50px;
        box-shadow: 0px 0px 70px gray;
    }
}

@media screen and (max-width: 900px) {

    .sec_icon_right,
    .sec_icon_left {
        width: 100px;
        top: 0%;
    }

    .sec_icon_left {
        left: 4%;
    }

    .sec_icon_right {
        right: 4%;
    }

    .img_abt_cont {
        margin: 20px 0px;
    }

    .container_phon {
        padding: 5px 20px;
        right: -120px;
    }

    .phon_img {
        width: 35px;
        height: 35px;
    }

    .container_phon h6 a {
        font-size: 22px;
    }

    .logo_container .MuiBox-root {
        padding: 15px 20px;
    }

    .btn_achore {
        font-size: 17px;
    }

    .banner_about_container {
        height: 400px;
    }

    .banner_about_container img {
        object-position: center;
    }

    .btn_academic_container button {
        font-size: 25px;
    }

    .tit_continer h2 {
        font-size: 25px !important;
    }

    .fist_cont,
    .sec_cont {
        /* text-align: center; */
        width: 90%;
    }

    .logo_class {
        width: auto !important;
    }

    .form_div {
        position: relative;
        margin: -20px auto 70px auto;
        right: 0;
        left: 0;
        top: -50px;
        box-shadow: 0px 0px 70px gray;
    }

    .mennu_mobile {
        display: block;
    }

    .mennu_mobile div {
        padding: 0 !important;
    }

    .aca_main_container {
        flex-wrap: wrap;
    }

    .academic_img_cont {
        width: 46%;
    }

    .btn_academic_container {
        flex-wrap: wrap;
    }

    .btn_academic_container button {
        width: auto;
        min-width: 25%;
    }

    .container_extra_acti,
    .fist_cont {
        flex-wrap: wrap;
    }

    .img_about {
        width: 90% !important;
        margin: 60px auto;
    }

    .edge_container,
    .fall_main_cont,
    .container_extra_acti,
    .Globle_cont_main,
    .all_infra_div {
        max-width: 90%;
        margin: 0 auto;
    }

    .container_extra_acti,
    .fist_cont {
        padding: 30px 0 0px 0;
    }


    .social_img_div {
        margin: 10px 0;
    }

    .bann_img_cls .text_ban_cls {
        padding: 10px 20px;
        /* background-color: rgba(255, 255, 255, 0.144); */
        margin: 0 auto;
        width: fit-content;
        left: 0px;
        right: 0;
        top: 0%;
        font-size: 37px;
        line-height: 37px;
    }
}

@media screen and (max-width:768px) {
    .title_data {
        max-width: 90%;
        margin: 0 auto;
        padding: 15px;
    }

    .main_p_cross {
        padding: 0 20px;
        font-size: 23px !important;
    }

    .fin_conte_icon {
        width: 70px;
    }

    .finnish_all_content:nth-child(3) {
        margin: 40px 0 0 0;
    }

    .finnish_all_content h2 {
        font-size: 22px;
    }

    .finnish_all_content h6 {
        font-size: 17px;
    }

    .finnish_all_content:nth-child(4) {
        border-bottom: 1px solid gray;
    }

    .finnish_all_content:nth-child(4)::after {
        content: "";
        background-color: gray;
        position: absolute;
        width: 1.5px;
        height: 70%;
        top: 20%;
        left: 0;
    }

    .finnish_all_content:nth-child(4)::before,
    .finnish_all_content:nth-child(2)::before {
        background-color: transparent;
    }

    .finnish_all_content {
        width: 35%;
    }

    .OurGlobleFaculty_img_text .name_image_contest,
    .OurGlobleFaculty_img_text .main_name_tit {
        font-size: 22px;
    }

    .OurGlobleFaculty_img_text {
        justify-content: center;
        align-items: center;
    }

    .globle_paragraph_cont,
    .globle_image_cont {
        width: 90%;
        margin: 0 auto;
        text-align: center;
    }

    .globle_paragraph_cont h2,
    .educluster_text_container h2 {
        font-size: 20px;
    }

    .p_global_edu {
        font-size: 23px !important;
    }

    .cross_all_text_div h4 {
        font-size: 15px !important;
        align-items: center;
    }

    .cross_all_text_div h2 {
        margin: 10px 0;
        font-size: 17px;
    }

    .cross_all_text_div h6 {
        font-size: 17px;
        line-height: 20px;
    }

    .cross_img_text_cont {
        height: 400px;
    }

    .finland_banner_div {
        height: unset;
    }

    .a_star_text {
        font-size: 40px !important;
    }

    .star_born_cont img {
        height: auto;
    }

    .activies_title_bg h3 {
        font-size: 35px;
    }

    .images_activi_cont {
        height: 600px;
    }

    .school_stages_container {
        gap: 30px;
    }

    .sch_images_cont {
        width: 80%;
        margin: 0 auto;
    }

    .extra_activities_main {
        gap: 20px;
    }

    .extra_text_container {
        top: unset;
        left: 0;
        right: 0;
        margin: 0 auto;
        bottom: 0px;
    }

    .image_activities_container {
        width: 90%;
        margin: 0 auto;
    }

    .fis_banner_cont .img_last_banner {
        height: 400px;
    }

    .fist_cont {
        gap: 10px;
    }

    .logo1,
    .logo2,
    .logo3 {
        width: 45%;
    }

    .footer_class {
        gap: 20px;
    }

    .sec_cont h4 {
        font-size: 20px;
    }

    .tit_continer h2 {
        padding: 10px 20px;
        font-size: 20px !important;
    }

    .tit_continer {
        width: 70%;
        margin: 0 auto;
    }

    .sec_icon_right,
    .sec_icon_left {
        width: 90px;
        top: 0%;
    }

    .sec_icon_left {
        left: 3%;
    }

    .sec_icon_right {
        right: 3%;
    }

    .bann_img_cls .text_ban_cls {
        top: 0%;
        right: 0;
        font-size: 30px;
        line-height: 33px;
    }

    .logo_container .MuiBox-root {
        gap: 25px;
    }

    .phone_class {
        gap: 10px;
    }

    .mennu_mobile svg {
        height: 30px;
        width: 30px;
    }

    .logo_class,
    .phone_class {
        width: 50% !important;
        display: flex;
    }

    .logo_class a,
    .new_logo a {
        width: auto !important;
        height: 45px !important;
        min-height: 45px !important;
        max-height: 45px !important;
    }

    .academic_img_titl h3 {
        font-size: 20px !important;
    }

}

@media screen and (max-width: 600px) {
    .pr_image_container img{
        height: 300px;
    }
    .social_img_div {
        width: 18px;
        height: 18px;
    }

    .social_img_div img {
        height: 18px;
    }

    .social_media_container {
        top: 100px;
        padding: 7px;
    }

    .bann_img_cls .banner_img {
        height: 100%;
        min-height: 310px;
    }

    .finnish_all_content {
        width: 38%;
    }

    .butn_lern_more {
        font-size: 20px !important;
    }

    .images_activi_cont {
        height: 500px;
    }

    .curriculum_text h5 {
        font-size: 23px;
    }

    .title_data {
        width: 90%;
        margin: 0 auto;
        padding: 10px 10px;
        text-align: center;
    }

    .title_data h2 {
        font-size: 30px;
    }

    .imags_text h3 {
        font-size: 40px;
    }

    .sch_images_cont {
        height: 400px;
    }

    .image_activities_container {
        height: 400px;
    }

    .point_extra_conte h4 {
        padding: 0 20px;
        font-size: 20px !important;
    }

    .point_extra_conte h6 {
        padding: 7px 20px;
    }

    .logo_class,
    .new_logo {
        height: auto;
    }

    .logo_container .MuiBox-root {
        padding: 10px 5px 10px 15px !important;
    }

    .sec_icon_right,
    .sec_icon_left {
        display: none;
        width: 70px;
        top: 0%;
    }

    .sec_icon_left {
        left: 3%;
    }

    .sec_icon_right {
        right: 3%;
    }

    .container_phon {
        padding: 10px;
        gap: 10px;
        align-items: normal;
        right: -80px;
    }

    .phon_img {
        width: 25px;
        height: 25px;
    }

    .container_phon h6,
    .container_phon h6 a {
        font-size: 15px;
    }

}

@media screen and (max-width:500px) {
    .point_extra_conte h6 {
        font-size: 15px !important;
    }

    .image_activities_container {
        height: 320px;
    }

    .educluster_text_container {
        padding: 30px 0;
    }

    .globle_paragraph_cont h2,
    .educluster_text_container h2,
    .p_global_edu {
        font-size: 18px !important;
    }

    .cross_all_text_div h4 {
        padding: 5px 0;
    }

    .cross_all_text_div h6,
    .cross_all_text_div h2 {
        font-size: 14px;
        line-height: 18px;
    }

    .school_stages_container {
        padding: 30px 0;
    }

    .curriculum_text h5 {
        font-size: 18px;
        width: 90%;
        padding: 30px 0 50px 0;
    }

    .cross_all_text_div h2 {
        min-height: unset;
    }

    .cross_img_text_cont {
        height: 300px;
        width: 85%;
    }

    .finnish_all_content h6 {
        width: auto;
    }

    .finnish_all_content h2 {
        margin: 10px auto;
        font-size: 20px;
    }

    .finnish_all_content {
        width: 40%;
    }

    .butn_lern_more {
        font-size: 15px !important;
        padding: 10px 25px !important;
    }

    .a_star_text {
        font-size: 27px !important;
    }

    .activies_title_bg h3 {
        font-size: 23px;
    }

    .images_activi_cont {
        height: 400px;
    }

    .title_data h2 {
        font-size: 25px;
    }

    .imags_text h3 {
        font-size: 30px;
    }

    .sch_images_cont {
        height: 270px;
    }

    .point_extra_conte h4,
    .extra_tit h3 {
        font-size: 15px !important;
    }

    .extra_tit {
        min-height: 30px;
        padding: 20px;
    }

    .point_extra_conte h4 {
        padding: 0;
    }

    .bann_img_cls .text_ban_cls {
        top: 0%;
        right: 0;
        font-size: 25px;
        line-height: 30px;
    }

    .form_div {
        width: 280px;
    }

    .container_phon {
        padding: 10px;
        right: -70px;
    }

    .phon_img {
        width: 25px;
        height: 25px;
    }

    .container_phon h6,
    .container_phon h6 a {
        font-size: 13px;
    }

}

@media screen and (max-width: 400px) {
    .bann_img_cls .text_ban_cls {
        font-size: 23px;
    }

    .finnish_all_content h2 {
        width: auto;
    }

    .finnish_all_content {
        padding: 15px;
    }

    .title_data h2 {
        font-size: 23px;
    }
}